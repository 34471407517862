import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZynyoApiAccount } from '@shared/models/api-account/zynyo.api.account';
import { DataService } from '../data/data.service';
import { UserService } from '@services/user/user.service';
import { SettingsService } from '@services/settings/settings.service';
import { ConfigService } from '@services/config/config.service';
import {ModalContentComponent} from '@shared/components/modal-content/modal-content.component';
import {TranslateService} from '@ngx-translate/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {KeycloakService} from 'keycloak-angular';
import {ErrorDisplayService} from '@services/error-display/error-display.service';

@Injectable({
    providedIn: 'root'
})
export class ApiAccountsService extends DataService {
    constructor(
        protected readonly http: HttpClient,
        protected readonly config: ConfigService,
        private readonly userService: UserService,
        private readonly translate: TranslateService,
        private readonly modalService: BsModalService,
        private readonly settingsService: SettingsService,
        private readonly keycloakService: KeycloakService,
        private readonly errorDisplayService: ErrorDisplayService
    ) {
        super('apiaccounts', http, config, false);
    }

    private _apiAccounts!: ZynyoApiAccount[];

    get apiAccounts(): ZynyoApiAccount[] {
        return this._apiAccounts;
    }

    set apiAccounts(value: ZynyoApiAccount[]) {
        this._apiAccounts = value;
    }

    loadEnabledApiKeys(): Promise<ZynyoApiAccount[]> {
        return this.getAll<ZynyoApiAccount[]>('', {enabled: true})
            .toPromise()
            .then((res) => {

                // If res.length is 0, that means there is no linked Signingservice account.
                if ( res ) {
                    if (res.length === 0) {
                        const errorMsg = this.translate.instant('error.unlinked_account');
                        const errorTitle = this.translate.instant('error.unlinked_account_title');

                        const initialState = {
                            message: errorMsg,
                            title: errorTitle,
                            backdrop: true,
                            ignoreBackDropClick: true,
                            closeBtn: false
                        };

                        this.modalService.show(ModalContentComponent, {initialState});
                        return Promise.reject(new Error('No API accounts found'));
                    }

                    // Filter api accounts result if departFilter is on
                    const roles: string[] = this.keycloakService.getUserRoles();
                    const viewAll = roles.indexOf('view_all') > -1;
                    if (this.settingsService.settings.departmentFilter
                        && !viewAll && this.settingsService.settings.apiAccountsSeparator) {
                        res = res.filter(apiaccounts => apiaccounts.name === this.userService.department);
                    }

                    let select: ZynyoApiAccount | null;
                    if (this.userService.department && res.length > 1 && this.settingsService.settings.apiAccountsSeparator) {
                        select = (res.find(apiAccount => apiAccount.name === this.userService.department)) ?? res[0];
                    } else if (this.settingsService.settings.apikey) {
                        select = res.find(val => val.apiKey === this.settingsService.settings.apikey) ?? res[0];
                    } else {
                        select = res[0];
                    }

                    this.ApiKey = select?.apiKey ?? '';
                    res = res.sort((a, b) => a.name?.localeCompare(b.name));
                    this.apiAccounts = res;
                }
                return res;
            }, (error: any) => {
                if (error.status === 401) {
                    const errorMsg = this.translate.instant('error.disabled_account');
                    const errorTitle = this.translate.instant('error.disabled_account_title');

                    const initialState = {
                        message: errorMsg,
                        title: errorTitle,
                        backdrop: true,
                        ignoreBackDropClick: true,
                        closeBtn: false
                    };

                    this.modalService.show(ModalContentComponent, {initialState});
                } else {
                    this.errorDisplayService.displayError(this.translate.instant('error.server_unavailable.title'), error.message);
                }
                return error;
            });
    }
}
