import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SignSignatory } from '@shared/models/signrequest/sign.signatory';
import {Subject, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-sr-submitter',
    templateUrl: './sr-submitter.component.html',
    styleUrls: ['./sr-submitter.component.scss']
})
export class SrSubmitterComponent implements OnInit, OnDestroy {
    @Input() submitter!: Partial<SignSignatory>;
    @Output() updateSubmitter = new Subject();
    readonly form: UntypedFormGroup;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly formbuilder: UntypedFormBuilder
    ) {
        this.form = this.formbuilder.group({
            name: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]]
        });

        this.subscription.add(this.form.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(300)
        ).subscribe(res => {
            console.log('res', res);
            this.submitter.name = res.name;
            this.submitter.email = res.email;
            this.updateSubmitter.next(this.submitter);
        }));
    }

    get emailControl() {
        return this.form.get('email');
    }

    ngOnInit() {
        this.form.patchValue(this.submitter);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    patchEmail(email: string) {
        this.emailControl?.patchValue(email);
    }

    emailErrors(errors: ValidationErrors) {
        if (errors) {
            this.emailControl?.setErrors(errors);
        }
    }
}
