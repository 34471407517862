import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from '@security/app-auth.guard';
import { RouteAccessGuard } from '@security/route-access.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [AppAuthGuard],
        canActivateChild: [RouteAccessGuard],
        children: [
            {
                path: '',
                redirectTo: 'sign-request',
                pathMatch: 'full'
            },
            {
                path: 'sign-request',
                loadChildren: () => import('./routes/sign-request/sign-request.module').then(m => m.SignRequestModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./routes/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'administration',
                loadChildren: () => import('./routes/administration/administration.module').then(m => m.AdministrationModule)
            },
            {
                path: 'edelivery',
                loadChildren: () => import('./routes/edelivery/edelivery.module').then(m => m.EdeliveryModule)
            },
            {
                path: 'contacts',
                loadChildren: () => import('./routes/contacts/contacts.module').then(m => m.ContactsModule)
            },
            {
                path: 'workflow',
                loadChildren: () => import('./routes/workflow/workflow.module').then(m => m.WorkflowModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./routes/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'api-accounts',
                loadChildren: () => import('./routes/api-accounts/api-accounts.module').then(m => m.ApiAccountsModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'sysops',
                loadChildren: () => import('@routes/sysops/sys-ops.module').then(m => m.SysOpsModule)
            },
            {
                path: 'templates',
                loadChildren: () => import('@routes/templates/templates.module').then(m => m.TemplatesModule)
            },
            {
                path: 'eseal',
                loadChildren: () => import('@routes/eseal/eseal.module').then(m => m.EsealModule)
            },
            // Not found
            {path: '**', redirectTo: 'documents'}
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: true })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
