import { Injectable } from '@angular/core';
import {administrationMenu, dashboardMenu, documentsMenu, edeliveryMenu, esealMenu, sysOpMenu} from '@routes/menu';
import { SettingsService } from '@services/settings/settings.service';
import { Permission } from '@shared/models/settings/role-authorisation';

export interface MenuItem {
    text: string;
    component?: string;
    permission?: Permission;
    heading?: boolean;
    link?: string;
    icon?: string;
    // elink?: string;
    // target?: string;
    // alert?: string;
    // submenu?: MenuItem[];
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private menuItems: MenuItem[];
    layout: any;

    constructor(
        private readonly settingsService: SettingsService
    ) {
        this.menuItems = [];

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: true,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };
    }

    private addMenu(items: MenuItem[]) {

        if ( items.filter(value => value.heading).length !== items.length ) {
            items.forEach((item) => {
                this.menuItems.push(item);
            });
        }
    }

    async getMenu(roles: string[]): Promise<MenuItem[]> {
        this.menuItems = [];

        await this.checkForAccess(documentsMenu, roles).then(value => this.addMenu(value));
        await this.checkForAccess(dashboardMenu, roles).then(value => this.addMenu(value));
        await this.checkForAccess(edeliveryMenu, roles).then(value => this.addMenu(value));
        await this.checkForAccess(esealMenu, roles).then(value => this.addMenu(value));
        await this.checkForAccess(administrationMenu, roles).then(value => this.addMenu(value));

        if (roles.includes('sysop')) {
            this.addMenu(sysOpMenu);
        }

        return [...new Set(this.menuItems)];
    }

    private checkForAccess(menu: any[], roles: string[]): Promise<MenuItem[]> {
        return this.settingsService.roleAuthorizations.then(roleAuthorizations => {
            let items: MenuItem[] = [];
            roles.forEach((role) => {
                const enabledComponents: string[] =
                    roleAuthorizations
                        .filter((roleAuthorization) => roleAuthorization.role === role)
                        .filter((roleAuthorization) => roleAuthorization.permission !== Permission.NONE)
                        .map((roleAuthorization) => roleAuthorization.component);

                const availableMenu = menu.filter(mi => mi.heading || enabledComponents.includes(mi.component));
                items =  [...items, ...availableMenu];
                return items;
            });
            return items;
        });
    }
}
