import { MenuItem } from '@services/menu/menu.service';
import { Permission } from '@shared/models/settings/role-authorisation';

const DocumentHeading: MenuItem = {
    text: 'header.sign_request',
    heading: true
};


const OpenDocuments: MenuItem = {
    text: 'header.open',
    component: 'OPEN_DOCUMENTS',
    link: '/sign-request/open',
    icon: 'far fa-file'
};

const ProcessedDocuments: MenuItem = {
    text: 'header.processed',
    component: 'PROCESSED_DOCUMENTS',
    link: '/sign-request/processed',
    icon: 'fas fa-file-alt'
};

const SubmittedDocuments: MenuItem = {
    text: 'header.submitted',
    component: 'SUBMITTED_DOCUMENTS',
    link: '/sign-request/submitted',
    icon: 'fas fa-file-export'
};

const SignRequest: MenuItem = {
    text: 'header.sign_request',
    component: 'SIGNREQUEST',
    permission: Permission.READWRITE,
    link: '/sign-request/request',
    icon: 'fas fa-plus'
};

const DashboardHeading: MenuItem = {
    text: 'header.dashboard_header',
    heading: true
};

const Dashboard: MenuItem = {
    text: 'header.dashboard',
    component: 'DASHBOARD',
    link: '/dashboard',
    icon: 'far fa-chart-bar'
};


const SettingsHeading: MenuItem = {
    text: 'header.config',
    heading: true
};

const Contacts: MenuItem = {
    text: 'header.contacts',
    component: 'CONTACTS',
    link: '/contacts',
    icon: 'fas fa-address-book'
};

const Templates: MenuItem = {
    text: 'header.templates',
    component: 'TEMPLATES',
    link: '/templates',
    icon: 'fas fa-file-signature'
};

const Workflow: MenuItem = {
    text: 'header.workflow',
    component: 'WORKFLOW',
    link: '/workflow',
    icon: 'fas fa-list-alt'
};

const ApiAccounts: MenuItem = {
    text: 'header.apiAccounts',
    component: 'APIACCOUNTS',
    link: '/api-accounts',
    icon: 'fas fa-share-alt'
};

const Users: MenuItem = {
    text: 'header.users',
    component: 'USERS',
    link: '/users',
    icon: 'fas fa-users'
};

const Settings: MenuItem = {
    text: 'header.settings',
    component: 'SETTINGS',
    link: '/administration/settings',
    icon: 'fas fa-cog'
};

const Authorisation: MenuItem = {
    text: 'header.authorisation',
    component: 'AUTHORISATION',
    link: '/administration/authorisation',
    icon: 'fas fa-user-tag'
};

const Sessions: MenuItem = {
    text: 'header.sessions',
    component: 'SESSIONS',
    link: '/administration/sessions',
    icon: 'fas fa-users'
};

const EdeliveryHeading: MenuItem = {
    text: 'header.edelivery',
    heading: true
};

const EdeliveryRequest: MenuItem = {
    text: 'header.edeliveryRequest',
    link: '/edelivery/request',
    component: 'EDELIVERY',
    icon: 'fas fa-plus'
};

const EdeliveryOpenDocuments: MenuItem = {
    text: 'header.open',
    component: 'EDELIVERY',
    link: '/edelivery/open',
    icon: 'far fa-file'
};

const EdeliveryProcessedDocuments: MenuItem = {
    text: 'header.processed',
    component: 'EDELIVERY',
    link: '/edelivery/processed',
    icon: 'fas fa-file-alt'
};


const EsealHeading: MenuItem = {
    text: 'header.eseal',
    heading: true
};

const Eseal: MenuItem = {
    text: 'header.eseal',
    component: 'ESEAL',
    link: '/eseal',
    icon: 'fas fa-stamp'
};


const SysOpHeading: MenuItem = {
    text: 'header.sysOp',
    heading: true
};

const SysOpAccounts: MenuItem = {
    text: 'header.sysOpAccounts',
    component: 'SYSOP',
    link: '/sysops',
    icon: 'fas fa-server'
};

const SysOpAddAccount: MenuItem = {
    text: 'header.sysOpAddAccount',
    component: 'SYSOP',
    link: '/sysops/create',
    icon: 'fas fa-plus'
};

const SysOpHelp: MenuItem = {
    text: 'header.sysOpHelp',
    component: 'HELP',
    link: '/sysops/help',
    icon: 'fas fa-info-circle'
};

export const dashboardMenu = [
    DashboardHeading,
    Dashboard
];

export const documentsMenu = [
    DocumentHeading,
    SignRequest,
    OpenDocuments,
    ProcessedDocuments,
    SubmittedDocuments
];

export const edeliveryMenu = [
    EdeliveryHeading,
    EdeliveryRequest,
    EdeliveryOpenDocuments,
    EdeliveryProcessedDocuments
];

export const esealMenu = [
    EsealHeading,
    Eseal
];

export const administrationMenu = [
    SettingsHeading,
    Workflow,
    Contacts,
    Templates,
    Users,
    ApiAccounts,
    Settings,
    Authorisation
];

export const sysOpMenu = [
    SysOpHeading,
    SysOpAccounts,
    SysOpAddAccount,
    SysOpHelp
];
